import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { getAnswers } from "../../api/commonApi";
import { handleFeedbackApi, isEmptyObject } from "../../util";
import { common, gptConstants, MESSAGES, SessionStorageKeys } from "../../helper";
import { addResponse, isLoading } from "../../redux/features/user/userSlice";
import { clearFavAns, setFavState } from "../../redux/features/favorite/favoriteAnsSlice";
import { updatePrompt, clearPromptText } from "../../redux/features/prompt/promptSlice";
import { addToHistory, resetValue, updateHistoryAns } from "../../redux/features/history/historySlice";

import "../../../src/styles/SideNav.css";
import "../../styles/TextArea.css";
import mic from "../../assets/svg/mic.svg";
import keyboard_return from "../../assets/svg/keyboard_return.svg";

const InputComponent = () => {
  const [disable, setDisable] = useState(false);
  const prompt = useSelector((state) => state?.prompt?.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleKeyDown = async (e) => {
    if (e.key === common.Enter && !e.shiftKey) {
      e.preventDefault();
      await sendPrompt();
    }
  };

  const sendPrompt = async () => {
    navigate("/dataAnalysis");
    dispatch(isLoading(true));
    dispatch(clearFavAns());
    dispatch(setFavState(""));

    let data = [];
    const q_id = uuidv4();
    let history = sessionStorage.getItem(SessionStorageKeys.prevHistory);

    if (history) {
      history = JSON.parse(history);
      data = {
        question_id: q_id,
        question: [...history, { role: common.user, content: prompt.trim() }],
      };
    } else {
      data = {
        question_id: q_id,
        question: [{ role: common.user, content: prompt.trim() }],
      };
    }

    try {
      setDisable(true);
      const responseData = await getAnswers(data);

      if (!isEmptyObject(responseData)) {
        const historyData = [...responseData?.answers?.past];
        let prevHistory = sessionStorage.getItem(SessionStorageKeys.prevHistory);
        if (!!prevHistory) {
          prevHistory = JSON.parse(prevHistory);
        } else {
          prevHistory = [];
        }
        const updatedPrevHistory = [...prevHistory, ...historyData];
        sessionStorage.setItem(
          SessionStorageKeys.prevHistory,
          JSON.stringify(updatedPrevHistory)
        );
        dispatch(updatePrompt(prompt.trim()));
      }

      if (!isEmptyObject(responseData)) {
        dispatch(addResponse(responseData));

        const user = JSON.parse(localStorage.getItem(common.user));
        const data = {
          question_id: responseData.question_id,
          userId: user.userId,
          question: prompt.trim(),
          answers: responseData.answers,
          isFavQn: false,
          feedbackType: common.NA,
        };
        await handleFeedbackApi(data);
        dispatch(updateHistoryAns({ payload: data, shouldReplace: false }));
        dispatch(addToHistory({ payload: data, shouldReplace: false }));
        setDisable(false);
        dispatch(clearPromptText(common.emptyString));
        dispatch(isLoading(false));
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(isLoading(false));
      setDisable(false);
    }
  };

  const handleClearRes = () => dispatch(resetValue());

  return (
    <div className="sticky-input">
      <div className="chatMainSection font14 bold w-70 d-flex justify-content-center align-items-center ">
        <div
          className="p-0 commandContainerChild"
        >
          <>
            <textarea
              className="gpt-input"
              name="prompt"
              placeholder={MESSAGES.Enter_A_PromptHere}
              id="textAreaContent"
              value={prompt}
              onChange={(e) => dispatch(updatePrompt(e.target.value))}
              onKeyDown={handleKeyDown}
              disabled={disable}
            />

            <div className="footer">
              {/* <div
                onClick={() => (!disable ? sendPrompt() : null)}
                className="footerIconDiv2"
              >
                <img alt="icons" className="mic-icon" src={mic} />
              </div> */}
              <div onClick={() => (!disable ? sendPrompt() : null)} className="enter-icon-container" >
                <img alt="icons" className="text-area-icons" src={keyboard_return} />
              </div>
            </div>
          </>
        </div>
      </div>
      <div className="pt-2">
        <button className="clear-button" style={{ width: "80px" }} onClick={() => handleClearRes()}>
          {gptConstants.Clear}
        </button>
      </div>
    </div>
  );
};

export { InputComponent };
