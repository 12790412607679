import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearSelection } from '../redux/features/selection/selectionSlice';
import quote from '../assets/svg/quote.svg'
import { updatePrompt } from '../redux/features/prompt/promptSlice';

const CustomMenu = () => {
    const { text, position } = useSelector((state) => state.selection);
    const { value } = useSelector((state) => state.prompt);
    const dispatch = useDispatch();

    const handleAction = () => {
        if (value?.length !== 0) {
            dispatch(updatePrompt(value + " " + text))
        } else {
            dispatch(updatePrompt(text))
        }
        window.getSelection().removeAllRanges();
        dispatch(clearSelection());
    };

    if (!text) return null;

    return (
        <div
            style={{
                position: 'absolute',
                top: `${position.y - 55}px`, // Adjust position as needed
                left: `${position.x}px`,
                background: 'lightgray',
                padding: '2px',
                borderRadius: '30%',
                border: "1px solid rgba(0, 0, 0, 0.4)",
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                zIndex: 1000, // Ensure it's on top
            }}
        >
            <button style={{
                border: "none",
                background: "none"
            }} onClick={handleAction}>
                <img src={quote} />
            </button>
        </div>
    );
};

export { CustomMenu };
