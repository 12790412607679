import React, { useState } from "react";

import { DomainKnowledge, Instructions, PendingInstructions } from "./";
import { domainConstants } from "../helper";

import "../styles/TabComponent.css";
import '../styles/DomainKnowledge.css';

const DomainPage = () => {
  const [activeTab, setActiveTab] = useState(domainConstants.domain);

  const renderContent = () => {
    switch (activeTab) {
      case domainConstants.domain:
        return (<div><DomainKnowledge /></div>);
      case domainConstants.instructions:
        return <div><Instructions /></div>;
      case domainConstants.pending:
        return <div> <PendingInstructions /></div>;
      default:
        return null;
    }
  };

  return (
    <div className="tab-container">
      <div className="tab-buttons">
        {domainConstants.tabs.map((tab) => (
          <button key={tab.id} className={`tab-button ${activeTab === tab.id ? "active" : ""}`} onClick={() => setActiveTab(tab.id)}>  {tab.label}</button>
        ))}
      </div>
      <div className="scrollable-container p-1">{renderContent()}</div>
    </div>
  );
};

export { DomainPage };
