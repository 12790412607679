import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [], //content displaying in the data analysis are from here
  historyState: [],
  localHistoryState: [],
};
const historySlice = createSlice({
  name: "historySlice",
  initialState,
  reducers: {
    updateHistoryAns(state, action) {
      const { payload, shouldReplace } = action.payload;
      if (shouldReplace) {
        state.value = payload;
      } else {
        let currentResponse = [payload, ...state.value];
        state.value = currentResponse;
           }
    },

    addToHistory(state, action) {
      const { payload } = action;
      const storage = JSON.parse(localStorage.getItem("myHistory"));

      if (!!storage) {
        localStorage.setItem(
          "myHistory",
          JSON.stringify([payload.payload, ...storage])
        ); // updating localStorage
        state.localHistoryState = [payload.payload, ...state.localHistoryState]; // updating redux state
      } else {
        localStorage.setItem(
          "myHistory",
          JSON.stringify([payload.payload, ...state.localHistoryState])
        ); // updating localStorage
        state.localHistoryState = [payload.payload, ...state.localHistoryState]; // updating redux state
      }
    },
    addToHistory2(state) {
      const user = JSON.parse(localStorage.getItem("user"));
      const storage = JSON.parse(localStorage.getItem("myHistory")); // getting data from localStorage
      const filterData = !!storage
        ? storage.filter((ele) => ele.userId === user.userId)
        : []; // filtering by userId
      !!filterData
        ? (state.localHistoryState = [...filterData])
        : (state.localHistoryState = []); // updating redux state
    },
    updateHistory(state, action) {
      const { payload } = action;
      state.value = payload.history;
    },
    resetValue(state) {
      state.value = [];
      sessionStorage.setItem("prevHistory", []);
    },
    resetHistory() {
      return initialState;
    },
  },
});

export const {
  addToHistory,
  addToHistory2,
  updateHistoryAns,
  updateHistory,
  resetValue,
  resetHistory,
} = historySlice.actions;
export default historySlice.reducer;
