import React, { memo, useEffect, useRef } from 'react';

const RenderGraph = memo(({ keyId, graphChoice, latestData, td, generateGraph }) => {
  const graphRef = useRef(null);

  useEffect(() => {
    if (graphChoice === "graph" && graphRef.current) {
      generateGraph(latestData, td, graphRef.current);
    }
  }, [graphChoice, latestData, td, generateGraph]);

  return <div style={{ marginBottom: '30px' }} ref={graphRef} id={`gd-${keyId}`}></div>;
}
);

export { RenderGraph }