import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { common, CustomMenu } from "../../helper";
import { SkeletonLoader, isEmptyArray } from "../../util";
import { clearSelection, setSelection } from "../../redux/features/selection/selectionSlice";
import { Chart } from "./";
import { DefaultAnalysis } from "../";

import "../../styles/GptStyles.css";

const DataAnalysis = () => {
  const [disabled, setDisabled] = useState(false);
  const historyData = useSelector((state) => state?.historyData?.value);
  const loading = useSelector((state) => state?.user?.loading);
  const dispatch = useDispatch();

  const finalHistoryData = historyData;

  useEffect(() => { }, [finalHistoryData]);
  
  const renderChart = useMemo(() => {
    return (
      <>
        {finalHistoryData?.map((ele, i) => (
          <div className="chart-card" key={ele.question_id}>
            <Chart
              key={ele.question_id}
              keyId={ele.question_id}
              {...ele}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </div>
        ))}
      </>
    );
  }, [finalHistoryData, loading]);

  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      dispatch(
        setSelection({
          text: selection.toString(),
          position: {
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY,
          },
        })
      );
    } else {
      dispatch(clearSelection());
    }
  };

  useEffect(() => {
    document.addEventListener(common.mouseup, handleSelection);
    return () => {
      document.removeEventListener(common.mouseup, handleSelection);
    };
  }, [dispatch]);

  return (
    <div className="d-flex flex-column gap-3 scrollable-container p-1">
      {!isEmptyArray(finalHistoryData) ? (
        <div>
          {loading && <SkeletonLoader type={common.chat} />}
          <CustomMenu />
          {renderChart}
        </div>
      ) : loading ? (
        <SkeletonLoader type={common.chat} />
      ) : (
        <div>
          <DefaultAnalysis />
        </div>
      )}
    </div>
  );
};

export { DataAnalysis };
