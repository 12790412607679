import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import { gptConstants, labels, SessionStorageKeys } from "../helper";
import { isEmptyArray, SkeletonLoader } from "../util";
import { resetValue, updateHistoryAns } from "../redux/features/history/historySlice";

import "../styles/Card.css";

const History = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const historyData = useSelector((state) => state?.historyData?.localHistoryState);
  const loading = useSelector((state) => state?.user?.isLoading);

  const handleClick = (ele) => {
    dispatch(resetValue());
    const data = { payload: [ele], shouldReplace: true };
    dispatch(updateHistoryAns(data));
    let history = sessionStorage.getItem(SessionStorageKeys.prevHistory);
    if (history) {
      history = JSON.parse(history);
      history.push(ele?.answers?.past);
      sessionStorage.setItem(SessionStorageKeys.prevHistory, JSON.stringify(history));
    } else {
      sessionStorage.setItem(
        SessionStorageKeys.prevHistory,
        JSON.stringify(ele?.answers?.past)
      );
    }
    navigate("/dataAnalysis");
  };

  return (
    <div className="d-flex flex-column gap-3 scrollable-container pl-1">
      <b className="componentLabel">{labels.history}</b>
      <div className="pr-4">
        {!isEmptyArray(historyData) ? (
          historyData?.map((card, index) => (
            <div
              className="cardContainerFAV pointer"
              key={index}
              onClick={() => handleClick(card)}
            >
              <Card.Body>
                <div className="fixed-height pointer">
                  <div className="cardLabel pointer">
                    <label className="text-headerIcon pointer">{gptConstants.Query}</label>
                    <label className="text-header pointer">
                      {card?.question}
                    </label>
                    <br />
                  </div>
                  <div className="cardLabel pointer">
                    <label className="text-headerIcon pointer">{gptConstants.Response}</label>
                    <p className="text-content pointer">
                      {card?.answers?.text_response}
                    </p>
                  </div>
                  <br />
                  <br />
                </div>
              </Card.Body>
            </div>
          ))
        ) : loading ? (
          <SkeletonLoader type="cards" />
        ) : (
          <label className="w-100 h-100 mt-5 d-flex justify-content-center align-items-center">
            {labels.NoHistoryData}
          </label>
        )}
      </div>
    </div>
  );
};

export { History };
