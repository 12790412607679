export const LocalStorage = {
  setItem: (key, value) => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem(key, value);
    }
  },
  getItem: (key) => {
    if (typeof window !== "undefined" && window.localStorage) {
      var value = localStorage.getItem(key);
      if (value && value.startsWith("function")) {
        return eval("(" + value + ")()");
      }
      return value;
    }
    return null;
  },
  removeItem: (key) => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.removeItem(key);
    }
  },
  clearAll: () => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.clear();
    }
  }
};