const MESSAGES = {
  SUCCESS: "success",
  ERROR: 'Error',
  Accepted: "Accepted !!",
  Failed_to_Accept: "Failed to Accept !!",
  Rejected: "Rejected !!",
  Failed_to_Reject: "Failed to Reject !!",
  Login_Successfull: "Login Successfull !!",
  LogOut_Successfull: "LogOut Successfull !!",
  FeedbackReceived: 'Feedback received',
  FailedTryAgain: 'Failed, try again!!',
  ErrorGeneratingPDF: 'Error generating PDF:',
  SomethingWentWrongWithGraph: 'Something went wrong with graph!!',
  FavoriteAddedSuccessfully: 'Favorite added successfully',
  FavoriteRemovedSuccessfully: 'Favorite removed successfully',
  AddedToFavorite: 'Added to Favorite',
  RemovedFromFavorite: 'Removed from Favorite',
  FailedPleaseTryAgain: 'Failed, please try again!!',
  ErrorInAddingFavorites: 'Error in Adding Favorites:',
  ErrorInDeletingFavorites:'Error in deleting Favorites:',
  Enter_A_PromptHere: 'Ask a query',
  StepsAreNotAvailableYet: 'Steps are not available yet...',
  Please_contact_message: "Please contact message...",
  ResponseHandler: "response Handler",
  A_token_is_required_for_authentication: "A token is required for authentication"
}

const ERROR_MESSAGES = {
  must_be_a_valid_email_address: "*Must be a valid email address",
  email_must_be_less_than_20_characters: "*Email must be less than 20 characters",
  email_is_required: "*Email is required",
  password_must_be_at_least_8_characters: "*Password must be at least 8 characters",
  password_is_required: "*Password is required",
  Invalid_Credentials: "Invalid Credentials",
  Invalid_current_password : "Invalid current password",
  Something_went_wrong_Please_try_again_later: "Something went wrong!! Please try again later.",
  Invalid_password : "Invalid password",
  ErrorFetchingDomainKnowledge: 'Error fetching domain knowledge:',
  FailedToDelete: 'Failed to delete',
  ErrorDeletingDomainKnowledge: 'Error deleting domain knowledge:',
  ErrorAddingDomainKnowledge: 'Error adding domain knowledge:',
  FailedToAddDomain: 'Failed to Add Domain!!',
  FailedToDeleted: 'Failed to Deleted !!',
  INVALID_TOKEN: "INVALID_TOKEN",
}

export {
  MESSAGES,
  ERROR_MESSAGES
}