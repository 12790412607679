import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ChartContainer, SideBar } from "./components";

const Layout = () => {

  return (
    <div className="layout" style={{ display: "flex", height: "100vh" }}>
      <div style={{ transition: "width 0.5s ease-in-out", }} >
        <SideBar />
      </div>
      <div className="main-content" style={{ flexGrow: 1, transition: "width 0.5s ease-in-out", }} >
        <main>
          <ChartContainer>
            <Outlet />
          </ChartContainer>
        </main>
      </div>
    </div>
  );
};

export default Layout;
