import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLoader = ({ type }) => {
  const renderContent = () => {
    switch (type) {
      case "chat":
        return (
          <div className="skeleton_parent gap-4 d-flex flex-column">
            <Skeleton width="65%" height={20} />
            <Skeleton width="35%" height={20} />
            <Skeleton width="58%" height={20} />
            {/* <Skeleton width="76%" height={20} /> */}
            {/* <Skeleton width="79%" height={20} /> */}
            <Skeleton height="20vh" />
          </div>
        );
      case "cards":
        return (
          <>
            <Skeleton height="9vh" count="3" className="mb-4" borderRadius={8} />
          </>
        );
      case "card":
        return (
          <>
            <Skeleton width="9%" height="8vh" count="1" className="mb-4 ms-auto" borderRadius={8} />
          </>
        );
    }
  };

  return (
    <div className="d-flex flex-column gap-4 w-100 h-100">
      {renderContent()}
    </div>
  );
};

export { SkeletonLoader };
