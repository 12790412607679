import { createSlice } from "@reduxjs/toolkit";
import { LocalStorage } from "../../../util/LocalStorage";
import { SessionStorageKeys } from "../../../helper";

const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {},
    response: [],
    loading: false,
    isLogout: false,
    activeLink: null
  },
  reducers: {
    addUser(state, action) {
      const data = {
        token: action.payload.token,
        userId: action.payload.userId,
        email: action.payload.email,
        username: action.payload.username,
        userType: action.payload.userType,
      };
      state.value = { userdata: data };
      LocalStorage.setItem(
        SessionStorageKeys.token,
        action.payload.token
      );
      LocalStorage.setItem(SessionStorageKeys.userId, action.payload.userId);
      LocalStorage.setItem(SessionStorageKeys.email, action.payload.email);
      LocalStorage.setItem(SessionStorageKeys.username, action.payload.username);
    },
    addResponse(state, action) {
      let oldResponse = [...state.response];
      let currentResponse = action.payload;
      let newResponse = [...oldResponse, currentResponse];

      state.response = newResponse;
    },
    isLoading(state, action) {
      state.loading = action.payload;
    },
    logoutUser(state, action) {
      state.loading = true;
      state.value = {};
      state.response = [];
      state.loading = false;
      LocalStorage.removeItem("token");
      LocalStorage.removeItem("userId");
      LocalStorage.removeItem("email");
      LocalStorage.removeItem("userEmail");
      LocalStorage.removeItem("cfstate");
      LocalStorage.removeItem("userData");
      LocalStorage.removeItem("user");
      LocalStorage.removeItem("username");
      LocalStorage.removeItem("myHistory");
      state.isLogout = !state.isLogout;
    },
    setActiveLink(state, action) {
      state.activeLink = action.payload;
    }
  },
});

export const { addUser, addResponse, logoutUser, isLoading, setActiveLink } = userSlice.actions;
export default userSlice.reducer;
