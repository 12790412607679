import { createSlice } from '@reduxjs/toolkit';

const selectionSlice = createSlice({
    name: 'selection',
    initialState: {
        text: null,
        position: { x: 0, y: 0 },
    },
    reducers: {
        setSelection: (state, action) => {
            state.text = action.payload.text;
            state.position = action.payload.position;
        },
        clearSelection: (state) => {
            state.text = null;
            state.position = { x: 0, y: 0 };
        },
    },
});

export const { setSelection, clearSelection } = selectionSlice.actions;

export default selectionSlice.reducer;
