import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

import { common, gptConstants, labels, SessionStorageKeys } from "../helper";
import { isEmptyArray, isEmptyObject, handleFeedbackApi, SkeletonLoader } from "../util";
import { getFavAns, getFavorites } from "../api/commonApi";
import { updateFavoriteList } from "../redux/features/prompt/promptSlice";
import { isLoading } from "../redux/features/user/userSlice";
import { resetValue, updateHistoryAns } from "../redux/features/history/historySlice";
import "../styles/Card.css";

const Favourite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [favourites, setFavourites] = useState([]);
  const user = JSON.parse(localStorage.getItem(common.user));
  const userId = user?.userId;
  const loading = useSelector((state) => state?.user?.loading);

  const getFavoritesData = async () => {
    const response = await getFavorites();
    if (!!response) {
      const keys = [];

      for (let key in response?.questions) {
        if (response?.questions?.hasOwnProperty(key)) {
          keys.push({ userId: userId, question: key });
        }
      }

      !isEmptyArray(keys)
        ? dispatch(updateFavoriteList(keys))
        : dispatch(updateFavoriteList([]));
      !isEmptyArray(keys) && setFavourites(keys);
    }
    dispatch(isLoading(false));
  };

  const handleFevClick = async (fav) => {
    dispatch(resetValue());
    dispatch(isLoading(true));
    navigate("/dataAnalysis");
    const q_id = uuidv4();
    const data = {
      question_id: q_id,
      question: fav.trim(),
    };
    let responseData = await getFavAns(data);

    if (!isEmptyObject(responseData)) {
      const data = {
        userId: userId,
        question_id: responseData.question_id,
        question: fav.trim(),
        answers: responseData.answers,
        isFavQn: true,
        feedbackType: common.NA,
      };
      if (!!data) {
        dispatch(isLoading(false));
        dispatch(updateHistoryAns({ payload: [data], shouldReplace: true }));
        const historyData = [...responseData?.answers?.past];
        sessionStorage.setItem(SessionStorageKeys.prevHistory, JSON.stringify(historyData));
        await handleFeedbackApi(data);
      }
    }
  };

  useEffect(() => {
    dispatch(isLoading(true));
    getFavoritesData();
  }, []);

  return (
    <div className="d-flex flex-column gap-3 scrollable-container pl-1">
      <b className="componentLabel">{labels.favourites}</b>
      <div className="pr-3">
        {!isEmptyArray(favourites) ? (
          favourites?.map((ele, i) => (
            <div className="cardContainerFAV cursorIcon" key={i} onClick={() => handleFevClick(ele.question)} >
              <Card.Body>
                <div className="dynamic-height cursorIcon">
                  <div className="cardLabel cursorIcon">
                    <label className="text-headerIcon cursorIcon">{gptConstants.Query}</label>
                    <label className="text-header cursorIcon">
                      {ele.question}
                    </label>
                    <br />
                  </div>
                </div>
              </Card.Body>
            </div>
          ))
        ) : loading ? (
          <SkeletonLoader type={common.cards} />
        ) : (
          <label className="w-100 h-100 mt-5 d-flex justify-content-center align-items-center">
            {labels.NoFavourites}
          </label>
        )}
      </div>
    </div>
  );
};

export { Favourite };
