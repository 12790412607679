import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser, setActiveLink } from "../redux/features/user/userSlice";
import { resetHistory } from "../redux/features/history/historySlice";
import { MESSAGES, labels, links, users } from "../helper";
import { useBigScreenMediaQuery, useDesktopMediaQuery } from "../util";

import expand_all from "../assets/svg/expand_all.svg";
import analytics from "../assets/svg/analytics.svg";
import history from "../assets/svg/history.svg";
import favorite from "../assets/svg/favorite.svg";
import description_blk from "../assets/svg/description_blk.svg";
import person_4 from "../assets/svg/person_4.svg";
import logout from "../assets/svg/logout.svg";
import analytics_blue from "../assets/svg/analytics_blue.svg";
import history_blue from "../assets/svg/history_blue.svg";
import favorite_blue from "../assets/svg/favorite_blue.svg";
import description_blue from "../assets/svg/description_blue.svg";
import person_blue from "../assets/svg/person_blue.svg";
import logout_blue from "../assets/svg/logout_blue.svg";
import GptLogo from "../assets/png/GileadGPTlogo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/SideBar.css";

const SideBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isDesktop = useDesktopMediaQuery();
  const isbigScreen = useBigScreenMediaQuery();

  const activeLink = useSelector((state) => state?.user?.activeLink);

  const loggedInUser = JSON.parse(localStorage.getItem(users.user))?.userType;

  useEffect(() => {
    dispatch(setActiveLink(location.pathname));
  }, [location.pathname]);

  const handleMenuIcon = () => setDrawerOpen(!drawerOpen);

  const handleLogout = () => {
    dispatch(resetHistory());
    dispatch(logoutUser());
    toast.success(MESSAGES.LogOut_Successfull);
    navigate("/");
  };

  const adminNavItemsTop = [
    { src: expand_all, action: handleMenuIcon },
    {
      src: analytics,
      activeSrc: analytics_blue,
      label: labels.dataAnalysis,
      link: links.dataAnalysis,
    },
    {
      src: history,
      activeSrc: history_blue,
      label: labels.history,
      link: links.history,
    },
    {
      src: favorite,
      activeSrc: favorite_blue,
      label: labels.favourites,
      link: links.favourites,
    },
    {
      src: description_blk,
      activeSrc: description_blue,
      label: labels.domainContext,
      link: links.domainContext,
    },
  ];

  const navItemsTop = [
    { src: expand_all, action: handleMenuIcon },
    {
      src: analytics,
      activeSrc: analytics_blue,
      label: labels.dataAnalysis,
      link: links.dataAnalysis,
    },
    {
      src: history,
      activeSrc: history_blue,
      label: labels.history,
      link: links.history,
    },
    {
      src: favorite,
      activeSrc: favorite_blue,
      label: labels.favourites,
      link: links.favourites,
    }
  ];

  const navItemsBottom = [
    {
      src: person_4,
      activeSrc: person_blue,
      label: labels.profile,
      link: links.pageNotFound,
    },
    {
      src: logout,
      activeSrc: logout_blue,
      label: labels.logOut,
      action: handleLogout,
    },
  ];

  return (
    <div className="app-container">
      <div className="app-container-body">
        {/* {!drawerOpen && (
          <nav className="navbar navbar-expand-lg bg-body-tertiary align-items-start">
            <div className="sideNavIcons">
              {(loggedInUser === users.admin ? adminNavItemsTop : navItemsTop).map(
                (item, index) => (
                  <React.Fragment key={index}>
                    {item.link && item ? (
                      <Link
                        to={item.link}
                        className={`iconButton ${activeLink === item.link && "activeLink"
                          }`}
                      >
                        <img title={item.label} alt="icon" className={item.label == "Home" ? "homeIcon" : ""} src={activeLink === item.link ? item.activeSrc : item.src} />
                      </Link>
                    ) : (
                      <button
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                        aria-expanded={drawerOpen}
                        aria-label="Toggle navigation"
                        className="iconButton"
                        onClick={item.action}
                      >
                        <img title={item.label} alt="icons" src={item.src} />
                      </button>
                    )}
                    {index <
                      (loggedInUser === users.admin
                        ? adminNavItemsTop.length - 1
                        : navItemsTop.length - 1) && (
                        <hr className="hrTag w-100" />
                      )}
                  </React.Fragment>
                )
              )}
            </div>

            <div className="sideNavIcons">
              {navItemsBottom.map((item, index) => (
                <React.Fragment key={index}>
                  {item.link ? (
                    <Link to={item.link} className="iconButton">
                      <img title={item.label} alt="icon" src={activeLink === item.link ? item.activeSrc : item.src} />
                    </Link>
                  ) : (
                    <button
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                      aria-expanded={drawerOpen}
                      aria-label="Toggle navigation"
                      className="iconButton"
                      onClick={item.action}
                    >
                      <img title={item.label} alt="icons" src={item.src} />
                    </button>
                  )}
                  {index < 1 && <hr className="hrTag w-100" />}
                </React.Fragment>
              ))}
            </div>
          </nav>
        )} */}

        <div
          className={`offcanvas offcanvas-start${drawerOpen ? "show" : ""}`}
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          aria-hidden={!drawerOpen}
          style={{
            // visibility: drawerOpen ? "visible" : "hidden",
            // width: drawerOpen ? "165px" : "0",
            visibility: "visible",
            width: isbigScreen ? '300px' : isDesktop ? '200px' : '165px',
            borderRadius: "6px",
            position: "relative",
            transition: "width .4s ease-in-out",
          }}
        >
          {/* <div
            style={{
              position: "relative",
              margin: "10px 0px 0px 16px",
            }}
          >
            <img
              title="Collapse"
              alt="icons"
              src={collapse_all}
              onClick={handleMenuIcon}
            />
          </div> */}
          <img title="Collapse" alt="icons" src={GptLogo} className="side_gilead_logo" />

          <hr className="hrTag" />
          <div className="pb-2 d-flex flex-column justify-content-between h-100">
            <div className="offcanvas-body">
              <ul className="navbar-nav gap-1">
                {(loggedInUser === users.admin ? adminNavItemsTop : navItemsTop).map((item, index) => (
                  <React.Fragment key={index}>
                    {item.link && (
                      <>
                        <li className="nav-item">
                          <Link className="nav-link" to={item.link}>
                            <div className="navLink">
                              <img className='side-bar-icons' title={item.label} alt="icon" src={activeLink === item.link ? item.activeSrc : item.src} />
                              <span className={`${activeLink === item.link ? "activeLink" : 'iconLabel'}`}>  {item.label}</span>
                            </div>
                          </Link>
                        </li>
                        {index < (loggedInUser === users.admin ? adminNavItemsTop.length - 1 : navItemsTop.length - 1) &&
                          (<hr className="hrTag mb-2" />)}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>

            <div className="offcanvas-body">
              <ul className="navbar-nav gap-1">
                {navItemsBottom.map((item, index) => (
                  <React.Fragment key={index}>
                    <li className="nav-item">
                      {item.link ? (
                        <Link className="nav-link" to={item.link}>
                          <div className="navLink">
                            <img className='side-bar-icons' title={item.label} alt="icon" src={activeLink === item.link ? item.activeSrc : item.src} />
                            <span className={`iconLabel ${activeLink === item.link && "activeLink"}`}>  {item.label}</span>
                          </div>
                        </Link>
                      ) : (
                        <button
                          className="nav-link w-100 p-0"
                          onClick={item.action}
                        >
                          <div className="navLink">
                            <img className='side-bar-icons' title={item.label} alt="icon" src={activeLink === item.link ? item.activeSrc : item.src} />
                            <span className={`iconLabel ${activeLink === item.link && "activeLink"}`}>  {item.label}</span>
                          </div>
                        </button>
                      )}
                    </li>
                    {index < 1 && <hr className="hrTag" />}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SideBar };
