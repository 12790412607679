import { createSlice } from "@reduxjs/toolkit";

const chatIdSlice = createSlice({
  name: "chatId",
  initialState: {
    value: {},
  },
  reducers: {
    updateChatId(state, action) {
      state.value = action.payload;
    },
  },
});

export const { updateChatId } = chatIdSlice.actions;
export default chatIdSlice.reducer;
