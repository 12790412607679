import React, { useMemo, useState } from "react";
import { isEmptyArray } from "../../util";
import footprint from "../../assets/svg/footprint.svg";
import { gptConstants, MESSAGES } from "../../helper";

const Steps = (response) => {
  const [steps, setSteps] = useState([]);

  const formatAndGetSteps = async () => {
    let steps = response?.response?.split("\n").filter((value) => value !== "");
    setSteps(steps);
  };

  useMemo(() => {
    !!response && formatAndGetSteps();
  }, [response]);

  return (
    <div className="steps-container" style={{ height: "auto" }}>
      <div className="step-content">
        <div className="step-header">
          <div className="header-left">
            <img alt="icons" className="steps-icons pe-2" src={footprint} />
            <span className='steps-font'>{gptConstants.STEPS}</span>
          </div>
        </div>
        <div className="step-item">
          {!isEmptyArray(steps)
            ? steps.map((ele, i) => {
              return (
                <React.Fragment key={i}>
                  <p className="steps-list">{`${ele}`}</p>
                  <hr />
                </React.Fragment>
              );
            })
            : <p className="steps-list">{MESSAGES.StepsAreNotAvailableYet}</p>}
        </div>
      </div>
    </div>
  );
};

export { Steps };
