import { createSlice } from "@reduxjs/toolkit";

const promptSlice = createSlice({
  name: "prompt",
  initialState: {
    value: '',
    favorite: [],
  },
  reducers: {
    updatePrompt(state, action) {
      state.value = action.payload;
    },
    clearPromptText(state, action) {
      state.value = action.payload;
    },
    updateFavoriteList(state, action) {
      state.favorite = action.payload;
    },
  },
});

export const { updatePrompt, clearPromptText, updateFavoriteList } = promptSlice.actions;
export default promptSlice.reducer;
