import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { Popover } from "react-tiny-popover";
import "react-loading-skeleton/dist/skeleton.css";
import Plotly from "plotly.js-dist-min";
import { useDispatch, useSelector } from "react-redux";

import { isEmptyArray, isEmptyObject, isNullOrEmpty } from "../../util";
import {
  AddToFavorites,
  deleteFavorites,
  DislikeFeedback,
  feedbackApi,
} from "../../api/commonApi";

import { RenderGraph } from "./RenderGraph";
import { GridTable, QueryViewer } from "../";

import area_chart from "../../assets/svg/area_chart.svg";
import table from "../../assets/svg/table.svg";
import terminal from "../../assets/svg/terminal.svg";
import heart from "../../assets/svg/heart.svg";
import heart_filled from "../../assets/svg/heart-filled.svg";
import thumbs_up from "../../assets/svg/thumbs-up.svg";
import thumbs_upFill from "../../assets/svg/thumbs_upFill.svg";
import thumbs_down from "../../assets/svg/thumbs-down.svg";
import thumbs_downFill from "../../assets/svg/thumbs_downFill.svg";
// import download from "../../assets/svg/download.svg";
import sentimentDissatisfied from "../../assets/svg/sentiment_dissatisfied.svg";
import keyboard_return from "../../assets/svg/keyboard_return.svg";
import { Steps } from "./Steps";
import {
  addToHistory2,
  updateHistoryAns,
} from "../../redux/features/history/historySlice";
import { common, gptConstants, MESSAGES, SessionStorageKeys } from "../../helper";

const Chart = memo(
  ({ feedbackType, isFavQn, question_id, question, answers, keyId, disabled, setDisabled, userId }) => {
    const dispatch = useDispatch();
    const chartContainerRef = useRef(null);
    const [prevResponseLength, setPrevResponseLength] = useState(0);

    const [chartHeight, setChartHeight] = useState("24vh");
    const [tableHeight, setTableHeight] = useState("40vh");
    const [chartWidth, setChartWidth] = useState("900");
    const [textHeight, setTextHeight] = useState("100%");
    const [toggleHeight, setToggleHeight] = useState(false);
    const [codeHeight, setCodeHeight] = useState("18vh");
    const [graphChoice, setGraphChoice] = useState("graph");
    const [fs, setFS] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [textResponse, setTextResponse] = useState("");
    const [code, setCode] = useState("");
    const [drawGraph, setDrawGraph] = useState(false);
    const [drawChart, setDrawChart] = useState(false);
    const [latestData, setlatestData] = useState({});
    const [td, setTd] = useState({});
    const [favorite, setFavorite] = useState(isFavQn);
    const [isLike, setIsLike] = useState(feedbackType === "+ve");
    const [isDisLike, setIsDisLike] = useState(feedbackType === "-ve");
    const [stepsToggle, setStepsToggle] = useState(false);
    const [queryToggle, setQueryToggle] = useState(false);

    const chatHistory_store = useSelector((state) => state?.historyData?.value);

    const queryData = answers?.data_table.map((ele, i) => ({ id: i + 1, ...ele.result[0], ...ele.input  })) || [];
    const queryBtnData = queryData.map((_, index) => ({id: index + 1, label: `Query ${index + 1}`})) || [];

    const transformArray = (originalObject) => {
      const transformedArray = Object.values(originalObject).reduce(
        (acc, key) => {
          acc.push({ field: key });
          return acc;
        },
        []
      );
      return transformedArray;
    };

    const handleKeyDown = async (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        await handleDislike();
      }
    };

    const changeHeight = (changes) => {
      if (!disabled) {
        if (changes) {
          setChartHeight("10vh");
          setChartWidth("900");
          setTableHeight("40vh");
          setTextHeight("100%");
          setCodeHeight("18vh");
          setToggleHeight(true);
        } else {
          setChartHeight("24vh");
          setChartWidth("900");
          setTableHeight("40vh");
          setTextHeight("100%");
          setCodeHeight("18vh");
          setToggleHeight(false);
        }
      }
    };

    const downloadChartImagePdf = async () => {
      const chartContainer = chartContainerRef.current;

      if (!chartContainer) {
        return; // Handle potential errors
      }

      try {
        const imageBlob = await domtoimage.toBlob(chartContainer);
        const imageDataURL = URL.createObjectURL(imageBlob);

        const img = await new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.src = imageDataURL;
        });

        const doc = new jsPDF({
          orientation: gptConstants.portrait,
          unit: gptConstants.px,
          format: gptConstants.a4,
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / img.width;
        const scaledImgWidth = img.width * widthRatio;
        const scaledImgHeight = img.height * widthRatio;

        let heightLeft = scaledImgHeight;
        let position = 0;
        let page = 1;

        while (heightLeft > 0) {
          // Center image horizontally
          const xOffset = (pageWidth - scaledImgWidth) / 2;

          // Add image to the current page
          doc.addImage(
            imageDataURL,
            gptConstants.PNG,
            xOffset,
            position,
            scaledImgWidth,
            scaledImgHeight
          );

          heightLeft -= pageHeight;
          position -= pageHeight;

          // Add new page if there's still more image to add
          if (heightLeft > 0) {
            doc.addPage();
            page++;
          }
        }

        doc.save(`chart_${Date.now()}.pdf`);
        URL.revokeObjectURL(imageDataURL);
      } catch (error) {
        console.error(MESSAGES.ErrorGeneratingPDF, error);
      }
    };

    const makeFullScreen = async (command) => {
      const page = document.getElementById(gptConstants.mycontainer);
      const name = gptConstants.fullscreen;
      const btn = document.getElementById(gptConstants.fullExpand);
      const name2 = gptConstants.fullscreenToggle;

      if (command) {
        if (!page.classList.contains(name)) {
          page.classList.add(name);
          btn.classList.add(name2);
        }
        setChartHeight("54vh");
        setTableHeight("54vh");
        setTextHeight("100%");
        setToggleHeight(true);
        setCodeHeight("100%");
        setDisabled(true);
      } else {
        const classList = page?.className.split(" ");
        const newClassList = classList.filter(
          (className) => className !== name
        );
        page.className = newClassList.join(" ");

        const classList2 = btn.className.split(" ");
        const newClassList2 = classList2.filter(
          (className) => className !== name2
        );
        btn.className = newClassList2.join(" ");
        setChartHeight("26vh");
        setTableHeight("40vh");
        setTextHeight("100%");
        setToggleHeight(false);
        setCodeHeight("18vh");
        setDisabled(false);
      }
      setFS(command);
    };

    const downloadPdf = async () => {
      const page = document.getElementById(gptConstants.mycontainer);
      const name = gptConstants.fullscreen;
      if (!page.classList.contains(name)) {
        // await makeFullScreen(true);
        setTimeout(async () => {
          await downloadChartImagePdf();
        }, 2000);
        setTimeout(async () => {
          // await makeFullScreen(false);
        }, 4000);
      } else {
        await downloadChartImagePdf();
      }
    };

    const generateGraph = useCallback(async (latestData, td) => {
      const graphData = latestData?.graph_code;
      if (
        !isNullOrEmpty(graphData) &&
        !isNullOrEmpty(graphData) &&
        graphData !== common.NA &&
        graphData !== common.NO &&
        graphData !== common.No
      ) {
        try {
          let code = JSON.parse(graphData);
          let x = td.row.map((val) => {
            return Object.values(val)[0];
          });
          let y = td.row.map((val) => {
            return Object.values(val)[1];
          });
          let trace = code?.data[0];
          const updateLayout = {
            ...code?.layout,
            width: window.innerWidth * 0.7,  // 70% of the screen width
            height: window.innerHeight * 0.8  // 80% of the screen height
          }

          let layout = updateLayout;
          trace.x = x;
          trace.y = y;

          if (graphChoice === gptConstants.graph) {
            let ui = document.getElementById(`gd-${keyId}`);
            await Plotly.newPlot(ui, [trace], layout);
          }
        } catch (error) {
          console.log(MESSAGES.SomethingWentWrongWithGraph);
        }
      }
    }, []);

    const fortmatData = async () => {
      //text response
      let textData = answers?.text_response;
      setTextResponse(textData);

      //graph,table and query code
      if (answers?.graph_display === common.YES && !isEmptyObject(answers?.data_table)) {
        setDrawGraph(true);
        setGraphChoice(gptConstants.graph);

        let dataQuery = answers?.data_table?.filter((value) => {
          return (
            value?.input?.user_query === answers?.subquestion_used_for_chart
          );
        });
        let queryObject;
        if (!isEmptyArray(dataQuery)) {
          queryObject = dataQuery[0];
        } else {
          queryObject = answers?.data_table[0];
        }
        if (!isEmptyObject(queryObject)) {
          setGraphChoice(gptConstants.graph);
          setDrawChart(true);
          let queryCode = queryObject?.result[0]?.query?.replaceAll(
            "    ",
            "\n"
          );
          setCode(queryCode);
          let sqlResponse = queryObject?.result[0]?.query_result;
          if (!isEmptyArray(sqlResponse)) {
            let zeroIndex = sqlResponse[0];
            let columnHeader = transformArray(zeroIndex);
            let rowDatas = sqlResponse?.slice(1);
            let table = {
              row: rowDatas,
              column: columnHeader,
            };
            setlatestData(answers);
            setTd(table);
            if (graphChoice === gptConstants.graph) {
              await generateGraph(answers, table);
            }
          } else {
            setDrawChart(false);
          }
        } else {
          setDrawChart(false);
        }
      }
      else if (answers.graph_code !== "NA") {
        setDrawGraph(false);

        let queryObject = answers?.data_table[0];
        if (!isEmptyObject(queryObject)) {
          setGraphChoice(gptConstants.table);
          setDrawChart(true);
          let queryCode = queryObject?.result[0]?.query?.replaceAll(
            "    ",
            "\n"
          );
          setCode(queryCode);
          let sqlResponse = queryObject?.result[0]?.query_result;
          if (!isEmptyArray(sqlResponse)) {
            let zeroIndex = sqlResponse[0];
            let columnHeader = transformArray(zeroIndex);
            let rowDatas = sqlResponse?.slice(1);
            let table = {
              row: rowDatas,
              column: columnHeader,
            };
            setTd(table);
          } else {
            setDrawChart(false);
          }
        } else {
          setDrawChart(false);
          setGraphChoice(common.NONE);
        }
      } else {
        setDrawChart(false);
        setGraphChoice(common.NONE);
      }
    };

    const getNecessaryDetails = async () => {
      const chatHistory = chatHistory_store.filter(
        (item) => item.question_id === question_id
      );
      let latestResponse = chatHistory[chatHistory.length - 1];
      let qn_id = latestResponse?.question_id;
      let question = latestResponse?.question;
      let textData = latestResponse?.answers?.text_response;

      let currentResIndex = chatHistory_store.findIndex((item) => item.question_id === question_id)
      const totalHistory = chatHistory_store.slice(currentResIndex)?.map(item => item.answers?.past);
      let history = totalHistory?.reverse()?.flat()

      //when we have multiple user_quey and sql_query in data_table >> send all to add fav
      let multi_user_sql_query = latestResponse?.answers?.data_table?.reduce(
        (acc, item) => {
          acc[item.input.user_query] = [item.result[0]?.query];
          return acc;
        },
        {}
      );

      // for Feedback api
      let dataQuery = latestResponse?.answers?.data_table?.filter((value) => {
        return (
          value?.input?.user_query ===
          latestResponse?.answers?.subquestion_used_for_chart
        );
      });
      let queryObject;
      if (!!dataQuery && !isEmptyArray(dataQuery)) {
        queryObject = dataQuery[0];
      } else {
        queryObject = latestResponse?.answers?.data_table[0];
      }
      let queryCode = queryObject?.result[0]?.query?.replaceAll("\n", "");
      let user_query = !isNullOrEmpty(queryObject?.input?.user_query) ? queryObject?.input?.user_query : "NA";

      return {
        user_query,
        queryCode,
        textData,
        question,
        history,
        multi_user_sql_query,
        qn_id,
      };
    };

    const updateHistoryValueStore = (key, value) => {
      const storageData = JSON.parse(localStorage.getItem(SessionStorageKeys.myHistory));
      if (!!storageData) {
        const updatedStorageData = storageData.map((item) => {
          if (
            userId === item.userId &&
            item.question.replace(/ /g, "") ===
            question.replace(/ /g, "") /* && item.userId === userId */
          ) {
            return { ...item, [key]: value };
          }
          return item;
        });
        localStorage.setItem(SessionStorageKeys.myHistory, JSON.stringify(updatedStorageData));
        dispatch(addToHistory2());
      }

      const updatedData = chatHistory_store.map((item) => {
        if (item.question_id === question_id) {
          return { ...item, [key]: value };
        }
        return item;
      });
      dispatch(updateHistoryAns({ payload: updatedData, shouldReplace: true }));
    };

    const handleFavorite = async () => {
      const user_data = await getNecessaryDetails();

      let input;
      if (!favorite) {
        input = {
          question: user_data?.question,
          // sql_query: { [user_data?.user_query]: [user_data?.queryCode] ?? "" },
          query: user_data?.multi_user_sql_query,
          past: user_data?.history,
        };
        try {
          const AddFavRes = await AddToFavorites(input);
          await updateHistoryValueStore(gptConstants.isFavQn, !favorite);

          if (
            !isEmptyObject(AddFavRes) &&
            AddFavRes.message === MESSAGES.FavoriteAddedSuccessfully
          ) {
            setFavorite(!favorite);
            toast.success(MESSAGES.AddedToFavorite);
          } else {
            toast.error(MESSAGES.FailedPleaseTryAgain);
          }
        } catch (error) {
          console.error(MESSAGES.ErrorInAddingFavorites, error);
        }
      } else {
        input = { fav_question: user_data?.question };
        try {
          const delFavRes = await deleteFavorites(input);
          await updateHistoryValueStore(gptConstants.isFavQn, !favorite);
          if (
            !isEmptyObject(delFavRes) &&
            delFavRes.message === MESSAGES.FavoriteRemovedSuccessfully
          ) {
            setFavorite(!favorite);
            toast.success(MESSAGES.RemovedFromFavorite);
          } else {
            toast.error(MESSAGES.FailedPleaseTryAgain);
          }
        } catch (error) {
          console.error(MESSAGES.ErrorInDeletingFavorites, error);
        }
      }
    };

    const handleFeedbackApi = async (type, comment) => {
      const user_data = await getNecessaryDetails();
      const data = {
        user_query: user_data?.user_query,
        query: gptConstants.sqlQuery /* user_data.queryCode */,
        response: user_data?.textData,
        feedback_received: true,
        feedback_type: type,
        comment: comment,
      };
      const feedbackRes = await feedbackApi(data);
      if (!!feedbackRes) {
        toast.success(MESSAGES.FeedbackReceived);
        if (type === common.POSITIVE) {
          setIsLike(!isLike);
          setIsDisLike(false);
          await updateHistoryValueStore(common.feedbackType, common.positive);
        } else {
          setIsLike(false);
          setIsDisLike(!isDisLike);
          await updateHistoryValueStore(common.feedbackType, common.negative);
        }
      } else {
        toast.error(MESSAGES.FailedTryAgain);
      }
    };

    const handleDislike = async () => {
      const input = {
        question: question,
        instruction: feedback.trim(),
      };
      const feedbackResponse = await DislikeFeedback(input);
      if (
        !isEmptyObject(feedbackResponse) &&
        feedbackResponse.status === common.success
      ) {
        setFeedback(common.emptyString);
        setIsPopoverOpen(!isPopoverOpen);
        await handleFeedbackApi(common.NEGATIVE, feedback.trim());
      }
    };

    const handleStepsToggle = () => {
      setStepsToggle(!stepsToggle);
      setQueryToggle(false);
    };
    const handleViewQueryToggle = () => {
      setStepsToggle(false);
      setQueryToggle(!queryToggle);
    };

    useEffect(() => {
      if (!isEmptyObject(answers)) {
        fortmatData();
        if (answers?.length > prevResponseLength) {
          setFavorite(false);
          setIsLike(false);
          setIsDisLike(false);
        }
        setPrevResponseLength(answers?.length);
      }
    }, [answers]);

    const memoizedRenderGraph = useMemo(
      () => (
        <RenderGraph
          graphChoice={graphChoice}
          latestData={latestData}
          td={td}
          generateGraph={generateGraph}
          keyId={keyId}
        />
      ),
      [graphChoice, latestData, td, generateGraph]
    );

    return (
      <>
        <div ref={chartContainerRef}>
          {!isEmptyObject(answers) && (
            <div style={{ display: "flex", flexDirection: "row", gap: 10, marginBottom: "10px" }} >
              <div>
                <div className="row graphText">
                  <div id="graphTextContainer" className="col-12 graphTextContainer" style={{ height: textHeight }} >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, marginBottom: "10px" }} >
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }} >
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }} >
                          <b className="mb-0 black markdown-content">
                            {gptConstants.Query}
                          </b>
                          <b className="mb-0 black question">{question}</b>
                        </div>
                      </div>
                      <div>
                        <img title="Favorite" alt="icons" className="icons" src={favorite ? heart_filled : heart} onClick={handleFavorite} />
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }} >
                      <b className="mb-0 font14 black markdown-content">{gptConstants.Response}</b>
                      <pre className="mb-0 black answer" style={{ width: '100%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {textResponse}
                      </pre>
                    </div>
                    <div></div>
                  </div>
                </div>
                <hr className="hrTag" />
                {!!drawGraph &&
                  <div className="col-12 row graphHeader2 d-flex justify-content-end">
                    <div className="col-10">
                      {/* <PopoverPicker color={color} onChange={setColor} /> */}
                    </div>
                    <div className="col-2 d-flex justify-content-center">
                      <div>
                        {drawGraph &&
                          (
                            <img onClick={() => setGraphChoice("graph")} alt="icons" className="icons me-2" src={area_chart} />
                          )}
                      </div>

                      <div>
                        {drawChart &&
                          (
                            <img onClick={() => setGraphChoice("table")} alt="icons" className="icons me-2" style={{ padding: "4px" }} src={table} />
                          )}
                      </div>
                      {/* <div>
                    {drawChart && (
                      <img
                        onClick={() => downloadPdf()}
                        alt="icons"
                        style={{ padding: "2px" }}
                        className="icons me-2"
                        src={download}
                      />
                    )}
                  </div> */}
                    </div>
                  </div>
                }

                <div className="row graph">
                  {(graphChoice === "graph" || graphChoice === "table") &&
                    <div className="col-12 graphChart">
                      {graphChoice === "graph" && memoizedRenderGraph}
                      {graphChoice === "table" && drawChart && (
                        <GridTable
                          width={"90%"}
                          height={tableHeight}
                          row={td?.row}
                          column={td?.column}
                        />
                      )}
                    </div>
                  }

                  <div className="steps_query">
                    <div className="col-12 row graphFooter align-items-center">
                      <div className="col-8 p-0">
                        <div className="select-btn-container">
                          <button className={!stepsToggle ? "select-button" : "select-button-selected"} onClick={() => handleStepsToggle()} >
                            {gptConstants.STEPS}
                          </button>
                          <button className={!queryToggle ? "select-button" : "select-button-selected"} onClick={() => handleViewQueryToggle()} >
                            {gptConstants.VIEW_QUERY}
                          </button>
                        </div>
                      </div>

                      <div className="col-4 d-flex flex-row justify-content-end gap-3">
                        <div className="setMid">
                          <img title="Thumbs Up" alt="icons" className="icons" src={!isLike ? thumbs_up : thumbs_upFill} onClick={() => !isLike && handleFeedbackApi("POSITIVE", "NA")} />
                        </div>

                        <Popover
                          isOpen={isPopoverOpen}
                          onClickOutside={() => setIsPopoverOpen(false)}
                          positions={gptConstants.positions}
                          // positions={['top']}
                          content={
                            <div className="thumbsDownContainer mr-4">
                              <div className="row thumbsDownHeader">
                                <span className="col-sm-8 bold font12 greyColor ps-0 pe-0">
                                  {gptConstants.LetUsKnowWhatWeCanDoBetter}
                                </span>

                                <div className="col-sm-2 thumbsDownHeaderIcon">
                                  <img alt="icon" src={sentimentDissatisfied} />
                                </div>
                              </div>

                              <div className="thumbsDownContentContainer">
                                <textarea
                                  className="thumbsDownContent font12"
                                  name="feedback"
                                  id="thumbsDownContent"
                                  value={feedback}
                                  placeholder={gptConstants.PleaseEnterYouFeedback}
                                  onChange={(e) => setFeedback(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                />
                              </div>

                              <div className="d-flex justify-content-end" onClick={() => handleDislike()} >
                                <img alt="icon" className="setMid icons mr-2" src={keyboard_return} />
                              </div>
                            </div>
                          }
                        >
                          <div className="setMid" onClick={() => !isDisLike && setIsPopoverOpen(!isPopoverOpen)} >
                            <img title="Thumbs Down" alt="icons" className="icons " src={!isDisLike ? thumbs_down : thumbs_downFill} />
                          </div>
                        </Popover>
                      </div>
                      {/* {!disabled && (
                    <div
                      title="Fullscreen"
                      className="col-lg-1 col-md-1 col-2 setMid"
                      onClick={() => makeFullScreen(!fs)}
                    >
                      <img alt="icons" className="icons" src={fullscreen} />
                    </div>
                  )} */}
                    </div>

                    {queryToggle && (
                      <div className="codeEdior">
                        <div /*onClick={() => changeHeight(!toggleHeight)}*/ className={toggleHeight ? "row CodeHeader" : " row CodeHeader2"} >
                          <div className="col-6">
                            <img alt="icons" className="icons me-2" src={terminal} />
                            <span className="semi-bold h-100 markdown-content greyColor textCenter">
                              {gptConstants.VIEW_QUERY}
                            </span>
                          </div>
                          <div className="col-6 setMidAndEnd">
                            {/* {!disabled && (
                              <img alt="icons" className="icons" src={toggleHeight ? collapse_all : expand_all} />
                            )} */}
                          </div>
                        </div>
                        <div
                          className={
                            toggleHeight && "codeEdiorContainer2"
                            // : "codeEdiorContainer"
                          }
                        // className={"codeEdiorContainer"}
                        >
                          <QueryViewer queryData={queryData} queryBtnData={queryBtnData} />
                        </div>
                      </div>
                    )}
                    {stepsToggle && <Steps response={answers?.steps} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div
        id="fullExpand"
        onClick={() => makeFullScreen(!fs)}
        className="close"
      >
        <img alt="icons" width={"60%"} height={"60%"} src={fullscreen_exit} />
      </div> */}
      </>
    );
  }
);

export { Chart };
