import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionStorageKeys } from "./helper";
import { LocalStorage } from "./util/LocalStorage";
import { Login, History, Favourite, DataAnalysis, DomainPage } from "./components";
import { addUser } from "./redux/features/user/userSlice";
import Layout from "./Layout";
import { addToHistory2 } from "./redux/features/history/historySlice";
import PageNotFound from "./pageNotFound";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const MainRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLogout = useSelector((state) => state?.user?.isLogout);

  useEffect(() => {
    const token = LocalStorage.getItem(SessionStorageKeys.token);
    const userId = LocalStorage.getItem(SessionStorageKeys.userId);
    const email = LocalStorage.getItem(SessionStorageKeys.email);
    const username = LocalStorage.getItem(SessionStorageKeys.username);

    if (token) {
      const userData = { token, userId, email, username };
      dispatch(addUser(userData));
      if (location?.pathname === "/") {
        navigate("/dataAnalysis");
      }
    } else {
      navigate("/");
    }
  }, [isLogout]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    !!email && dispatch(addToHistory2());
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/domainContext" element={<DomainPage />} />
          <Route path="/dataAnalysis" element={<DataAnalysis />} />
          <Route path="/history" element={<History />} />
          <Route path="/favourites" element={<Favourite />} />
          <Route path="/pageNotFound" element={<PageNotFound />} />
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </>
  );
}

const App = () => {
  return (
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  );
}

export default App;