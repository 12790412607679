import { configureStore } from "@reduxjs/toolkit";

import userSliceReducer from "./features/user/userSlice";
import promptSliceReducer from "./features/prompt/promptSlice";
import chatIdSliceReducer from "./features/chatId/chatIdSlice";
import favoriteAnsSliceReducer from "./features/favorite/favoriteAnsSlice";
import historySliceReducer from "./features/history/historySlice";
import selectionSliceReducer from "./features/selection/selectionSlice";


export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    prompt: promptSliceReducer,
    chatId: chatIdSliceReducer,
    favAnswer: favoriteAnsSliceReducer,
    historyData: historySliceReducer,
    selection: selectionSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
