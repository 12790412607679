import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SkeletonLoader, isEmptyObject, isNullOrEmpty } from "../util";
import { MESSAGES, labels, common, buttonLabels } from "../helper";
import { isLoading } from "../redux/features/user/userSlice";
import { acceptPendingInstruction, getPendingInstructions, rejectPendingInstruction } from "../api/commonApi";
import "../styles/loader.css";

const PendingInstructions = () => {
  const [pendingInstr, setPendingInstr] = useState({});
  const loading = useSelector((state) => state?.user?.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    displayInstructionsFn();
  }, []);

  const displayInstructionsFn = async () => {
    dispatch(isLoading(true));
    try {
      const response = await getPendingInstructions();
      if (response?.status === MESSAGES.SUCCESS && !isEmptyObject(response?.data) && !isNullOrEmpty(response?.data)) {
        setPendingInstr(response.data);
      } else {
        setPendingInstr({})
      }
    } catch (error) {
      console.error(MESSAGES.ERROR, error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const handleAccept_Reject = async (type, question, instruction) => {
    try {
      dispatch(isLoading(true));
      let data, res;
      if (type === common.accept) {
        data = { question, instruction };
        res = await acceptPendingInstruction(data);
        if (res?.status === MESSAGES.SUCCESS) {
          toast.success(MESSAGES.Accepted);
        } else {
          toast.error(MESSAGES.Failed_to_Accept);
        }
      } else {
        data = { question };
        res = await rejectPendingInstruction(data);
        if (res?.status === MESSAGES.SUCCESS) {
          toast.success(MESSAGES.Rejected);
        } else {
          toast.error(MESSAGES.Failed_to_Reject);
        }
      }
      if (res?.status === MESSAGES.SUCCESS) {
        await displayInstructionsFn();
      }
    } catch (error) {
      console.error(MESSAGES.ERROR, error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  return (
    <div className="domain-knowledge-container pr-4">
      <div className="domain-knowledge-container">
        {!isEmptyObject(pendingInstr) && !loading ? (
          Object.keys(pendingInstr)?.map((item, index) => (
            <div key={index} className="row knowledge-card m-0">
              <div className="col-8">
                <div className="domain-list text-start m-0">
                  <span className="font-weight-bold">{labels.userQuestion}</span> &nbsp;
                  <span>{item}</span>
                </div>
                <div className="domain-list text-start m-0">
                  <span className="font-weight-bold">{labels.userInstructions}</span> &nbsp;
                  <span>{pendingInstr[item]}</span>
                </div>
              </div>

              <div className="d-flex gap-2 col-4 p-0 justify-content-end">
                <button className="col-5 delete-button" onClick={() => handleAccept_Reject(common.accept, item, pendingInstr[item])}>  {buttonLabels.Accept}</button>
                <button className="col-5 delete-button" onClick={() => handleAccept_Reject(common.reject, item, null)}> {buttonLabels.Reject}</button>
              </div>
            </div>
          ))
        ) : loading ? (
          <SkeletonLoader type="cards" />
        ) : (
          <div className="markdown-content bold w-100 h-100 mt-5 d-flex justify-content-center align-items-center">
           {common.DataIsEmpty}
          </div>
        )}
      </div>
    </div>
  );
};

export { PendingInstructions };