// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.tab-buttons {
  display: flex;
  width: 100%;
  gap: 10px;
  padding-right: 26px;
}

.tab-button {
  flex: 1 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid var(--border);
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button.active {
  background-color: var(--background-prompt);
  border: 1px solid var(--label-blue);
}

.tab-content {
  flex: 1 1;
  padding: 20px;
}

@media (min-width: 1440px) {
  .tab-button {
    font-size: clamp(16px, 2vw, 17px);
  }
}

@media (min-width: 2000px) {
  .tab-button {
    font-size: clamp(17px, 2vw, 24px);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/TabComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,+BAA+B;EAC/B,yBAAyB;EACzB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,0CAA0C;EAC1C,mCAAmC;AACrC;;AAEA;EACE,SAAO;EACP,aAAa;AACf;;AAEA;EACE;IACE,iCAAiC;EACnC;AACF;;AAEA;EACE;IACE,iCAAiC;EACnC;AACF","sourcesContent":[".tab-container {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.tab-buttons {\n  display: flex;\n  width: 100%;\n  gap: 10px;\n  padding-right: 26px;\n}\n\n.tab-button {\n  flex: 1;\n  padding: 10px;\n  font-size: 16px;\n  border-radius: 10px;\n  border: 1px solid var(--border);\n  background-color: #ffffff;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.tab-button.active {\n  background-color: var(--background-prompt);\n  border: 1px solid var(--label-blue);\n}\n\n.tab-content {\n  flex: 1;\n  padding: 20px;\n}\n\n@media (min-width: 1440px) {\n  .tab-button {\n    font-size: clamp(16px, 2vw, 17px);\n  }\n}\n\n@media (min-width: 2000px) {\n  .tab-button {\n    font-size: clamp(17px, 2vw, 24px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
