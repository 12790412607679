// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff80;;
    width: 100vw;
    height: 100vh;
  }
  
.skeleton_parent{
  border: 1px solid var(--default-border);
  /* filter: drop-shadow(0px 4px 6px var(--shadow)) !important;  */
  border-radius: 10px !important; 
  padding: 24px;
  margin-right: 12px;
  margin-bottom: 25px;
  background-color: var(--white) !important; 
}

  /* ProfileCard.css */
.profile-card {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 400px;
  margin: 20px auto;
}

.avatar img {
  border-radius: 50%;
}

.info {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;IAC3B,YAAY;IACZ,aAAa;EACf;;AAEF;EACE,uCAAuC;EACvC,gEAAgE;EAChE,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;AAC3C;;EAEE,oBAAoB;AACtB;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT","sourcesContent":[".full-page-loader {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    z-index: 2000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff80;;\n    width: 100vw;\n    height: 100vh;\n  }\n  \n.skeleton_parent{\n  border: 1px solid var(--default-border);\n  /* filter: drop-shadow(0px 4px 6px var(--shadow)) !important;  */\n  border-radius: 10px !important; \n  padding: 24px;\n  margin-right: 12px;\n  margin-bottom: 25px;\n  background-color: var(--white) !important; \n}\n\n  /* ProfileCard.css */\n.profile-card {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 10px;\n  max-width: 400px;\n  margin: 20px auto;\n}\n\n.avatar img {\n  border-radius: 50%;\n}\n\n.info {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
