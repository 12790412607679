import axios from "axios";
import axiosRetry from "axios-retry";

import { Url } from "./apiRoutes";
import { fortmatData, LocalStorage } from "../util";
import { common, SessionStorageKeys } from "../helper";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, MESSAGES } from "../helper";

export const axiosInstance = axios.create({
  baseURL: `${Url.llmUrl}`,
  timeout: 240000,
});

axiosInstance.defaults.headers = {
  "Content-Type": "application/json",
};

axiosRetry(axiosInstance, {
  retries: 2,
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
  retryCondition: axiosRetry.isRetryableError,
  onRetry: (retryCount, error) =>
    console.log(`${common.retry_count} ${retryCount}, ${common.error}${error}`),
});

const DEBUG = false;
// const DEBUG = process.env.NODE_ENV === "development";

function errorResponseHandler(error) {
  if (DEBUG) {
    console.error(`${common.Error} ${fortmatData(error)}`);
  }

  if (error.response && error.response.data) {
    if (error.response.data === MESSAGES.A_token_is_required_for_authentication) {
      LocalStorage.clearAll();
      window.location.href = "/";
    } else if (error.response.data === ERROR_MESSAGES.INVALID_TOKEN) {
      LocalStorage.clearAll();
      window.location.href = "/";
    } else if (error.response.data === ERROR_MESSAGES.ERROR_MESSAGES) {
      console.log(ERROR_MESSAGES.Invalid_current_password);
    } else if (error.response.status === 500) {
      toast.warn(ERROR_MESSAGES.Something_went_wrong_Please_try_again_later)
    }
    else {
      console.log(MESSAGES.ResponseHandler, error.response.data);
    }

  } else if (error.message) {
    console.log(error.message);
  } else {
    console.log(MESSAGES.Please_contact_message);
  }
  return error;
}

axiosInstance.interceptors.request.use(function (config) {
  const token = LocalStorage.getItem(SessionStorageKeys.SessionToken);
  config.headers.Authorization = token ? `${token}` : common.emptyString;

  if (DEBUG) {
    console.info(`${common.Request} ${fortmatData(config)}`);
  }

  return config;
}, errorResponseHandler);

axiosInstance.interceptors.response.use(function (response) {
  if (DEBUG) {
    console.info(`${common.Response}${fortmatData(response)}`);
  }
  return response;
}, errorResponseHandler);

export const getAPICall = async (url, data = {}) =>
  await axiosInstance.get(url, {
    params: data,
    signal: data?.signal
  });

export const postAPICall = async (url, data = {}) =>
  await axiosInstance.post(url, data, {
    signal: data?.signal
  });

export const putAPICall = async (url, data = {}) =>
  await axiosInstance.put(url, data, {
    signal: data?.signal
  });

export const deleteAPICall = async (url, data = {}) =>
  await axiosInstance.delete(url, {
    data,
    signal: data?.signal
  });
//thirdparty API
// export const llmPostAPICall = async (url, data) => await axios.get(url, data);
