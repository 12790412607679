const Url = {
  baseApiUrl: "https://qg4ko4efob.execute-api.ap-south-1.amazonaws.com/dev/v1",
  llmUrl: "http://34.207.119.110/api/", // collabera
  // llmUrl: "https://desntci8pd.ap-south-1.awsapprunner.com",
};
const apiRoutes = {
  userLogin: "/userLogin/",
  getAnswers: "/getAnswers/",
  updateQuestion: "/updateQuestion/",
  DislikeFeedback: "/addUserInstruction/",
  AddToFavorites: "/addFavorites/",
  deleteFavorites: "/deleteFavorites/",
  getFavorites: "/getFavorites/",
  displayDomainKnowledge: "/displayDomainKnowledge/",
  deleteDomainKnowledge: "/deleteDomainKnowledge/",
  addDomainKnowledge: "/addDomainKnowledge/",
  getPendingInstructions: "/getPendingInstructions/",
  rejectPendingInstruction: "/rejectPendingInstruction/",
  acceptPendingInstruction: "/acceptPendingInstruction/",
  displayAdminInstructions: "/displayAdminInstructions/",
  deleteAdminInstruction: "/deleteAdminInstruction/",
  feedback: "/feedback/",
  getFavAns: "/getFavAns/",
};

export { Url, apiRoutes };
