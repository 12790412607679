import { InputComponent } from "./InputComponent";
import "../../styles/GptStyles.css";

const ChartContainer = ({ children }) => {
  return (
    <div className="gpt-container">
      <div className="row graphHeader">
        <InputComponent className="sticky-input" />
        <div className="col-lg-10 col-md-8 col-sm-8"></div>
      </div>
      <div className="chart_render_block mt-4 pb-4">{children}</div>
    </div>
  );
};

export { ChartContainer };
