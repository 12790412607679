import { feedbackApi } from "../api/commonApi";
import { isEmptyArray, isNullOrEmpty } from "./";

const getUser_and_sql_query = async (chatResponse) => {
  const latestResponse = chatResponse;
  let textData = latestResponse?.answers?.text_response;
  let dataQuery = latestResponse?.answers?.data_table?.filter((value) => {
    return (
      value?.input?.user_query ===
      latestResponse?.answers?.subquestion_used_for_chart
    );
  });
  let queryObject;
  if (!!dataQuery && !isEmptyArray(dataQuery)) {
    queryObject = dataQuery[0];
  } else {
    queryObject = latestResponse?.answers?.data_table[0];
  }
  let queryCode = queryObject?.result[0]?.sql_query?.replaceAll("    ", "\n");
  let user_query = queryObject?.input?.user_query;
  return { user_query, queryCode, textData };
};

export const handleFeedbackApi = async (chatResponse) => {
  const user_data = await getUser_and_sql_query(chatResponse);
  const feedbackData = {
    user_query: chatResponse.question,
    query: !isNullOrEmpty(user_data.queryCode) ? user_data.queryCode : "NA",
    response: user_data.textData,
    feedback_received: false,
    feedback_type: "NA",
    comment: "NA",
  };
  await feedbackApi(feedbackData);
};
