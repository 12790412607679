import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { domainConstants, common, ERROR_MESSAGES } from "../helper";
import { isEmptyArray, isNullOrEmpty, SkeletonLoader } from "../util";
import { isLoading } from "../redux/features/user/userSlice";
import { addDomainKnowledge, deleteDomainKnowledge, displayDomainKnowledge } from "../api/commonApi";

import "../styles/DomainKnowledge.css";

const DomainKnowledge = () => {
  const [knowledgeItems, setKnowledgeItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [newKnowledge, setNewKnowledge] = useState(common.emptyString);
  const loading = useSelector((state) => state?.user?.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    displayDomainKnowledgeFN();
  }, []);

  const displayDomainKnowledgeFN = async () => {
    dispatch(isLoading(true));
    try {
      const response = await displayDomainKnowledge();
      if (response?.status === common.success && !isEmptyArray(response?.data) && !isNullOrEmpty(response?.data)) {
        setKnowledgeItems(response?.data);
      } else {
        setKnowledgeItems([])
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ErrorFetchingDomainKnowledge, error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const deleteDomainKnowledgeFN = async (item) => {
    dispatch(isLoading(true));
    try {
      const data = {
        description: item,
      };

      let deleteRes = await deleteDomainKnowledge(data);
      // After successful deletion, refresh the list
      if (deleteRes?.status == common.success) {
        toast.success(common.Deleted);
        await displayDomainKnowledgeFN();
      } else {
        toast.error(ERROR_MESSAGES.FailedToDelete);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ErrorDeletingDomainKnowledge, error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const addDomainKnowledgeFN = async () => {
    setModalLoading(true);
    if (newKnowledge.trim()) {
      dispatch(isLoading(true));
      try {
        const data = {
          description: newKnowledge,
        };
        const res = await addDomainKnowledge(data);
        if (res?.status === common.success) {
          toast.success(common.DomainAdded);
        } else {
          toast.error(ERROR_MESSAGES.FailedToAddDomain);
        }
        setNewKnowledge(common.emptyString);
        setShowModal(false);
        await displayDomainKnowledgeFN();
      } catch (error) {
        console.error(ERROR_MESSAGES.ErrorAddingDomainKnowledge, error);
      } finally {
        dispatch(isLoading(false));
      }
    }
    setModalLoading(false);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="domain-knowledge-wrapper pr-4">
      <div className="d-flex justify-content-end align-items-center w-100">
        <button disabled={loading} className="add-button" onClick={() => setShowModal(true)}>  {common.Add}</button>
      </div>
      <div className="domain-knowledge-container">
        {!isEmptyArray(knowledgeItems) && !loading ? (
          knowledgeItems?.map((item, index) => (
            <div key={index} className="row knowledge-card m-0 ">
              <p className="col-10 text-start m-0 domain-list">{item}</p>
              <button className=" col-2 delete-button" onClick={() => deleteDomainKnowledgeFN(item)}>  {common.Delete}</button>
            </div>
          ))
        ) : loading ? (
          <SkeletonLoader type={common.cards} />
        ) : (
          <div className="font14 bold w-100 h-100 mt-5 d-flex justify-content-center align-items-center">
            {common.DataIsEmpty}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{domainConstants.AddDomainKnowledge}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="newKnowledgeForm">
              <Form.Label>{common.Description}</Form.Label>
              <Form.Control as="textarea" rows={3} value={newKnowledge} onChange={(e) => setNewKnowledge(e.target.value)} placeholder={domainConstants.EnterNewDomainknowledge} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>  {common.Cancel}</Button>
          <Button disabled={modalLoading} variant="primary" onClick={addDomainKnowledgeFN} style={{ backgroundColor: "var(--text-lightBlue, #8dc1c5)" }}>  {modalLoading ? common.Loading : common.Add}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { DomainKnowledge };
