import Book from "../assets/svg/Book.svg";
import Chat from "../assets/svg/Chat.svg";
import Growth from "../assets/svg/Growth.svg";
import Processing from "../assets/svg/Processing.svg";
import SearchLens from "../assets/svg/SearchLens.svg";
import Puzzle from "../assets/svg/Puzzle.svg";


const SessionStorageKeys = {
  user: "user",
  token: "token",
  userId: "userId",
  email: "email",
  username: "username",
  myHistory: 'myHistory',
  prevHistory: 'prevHistory',
};

const CSS = {
  inputField: "rg-input-field",
  select: "rg-select-field",
  source: "rg-select-field-source",
  phoneNo: "rg-phone-no",
  container: "page-container",
  firstBlock: "first-half",
  secondBlock: "second-half",
  thanksHeader: "thaks-header",
  thanksMessage: "thanks-message",
  registerHeader: "rg-register-header",
  inputInfo: "rg-input-info-label",
  optionField: "rg-option-field",
  errorWithPadding: "error-message error-message-padding",
  errorMessage: "error-message",
  errorMessage2: "error-message2",
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const adminDashboard = {
  UserQuestion: 'User question:',
  Instructions: 'Instructions:',
}

const cardsDetails = [
  {
    img: Book,
    header: "Quick Access to Bookmarked Questions",
    subHeader:
      "Save & quickly access your most frequently asked questions or queries for future use",
  },
  {
    img: Chat,
    header: "Interactive Conversational Interface",
    subHeader:
      "Engage with the AI tool using an intuitive, conversational interface for natural & fluid communication",
  },
  {
    img: Processing,
    header: "Natural Language to SQL Conversion",
    subHeader:
      "Translate your natural language queries into structured SQL commands for seamless database interactions",
  },
  {
    img: SearchLens,
    header: "Insightful ‘Why’ Question Analysis",
    subHeader:
      "Clear, structured explanations by logically breaking down & answering ‘why’ questions across key drivers ",
  },
  {
    img: Growth,
    header: "Continuous Improvement Feedback",
    subHeader:
      "Provide valuable feedback & track your interactions to continuously refine the tool’s performance & user experience",
  },
  {
    img: Puzzle,
    header: "Realtime Integration with APIs(WIP)",
    subHeader:
      "Seamlessly integrate the tool with other applications to leverage GenAI capabilities & streamline data analysis across platforms",
  },
];

const sampleQns = [
  "How many Oncologists prescribe with TNBC market?",
  "What is the overlap of 2L and 3L+ “Product A” HCPs in TNBC?",
  "What are the existing sources of business for Product A in TNBC?",
  "What are the existing sources of business for Product B in TNBC?",
  "How does Product A new patient share vary on a monthly basis?",
  "what is Source of Business distribution for Product A in TNBC",
];

const common = {
  POSITIVE: 'POSITIVE',
  feedbackType: 'feedbackType',
  positive: '+ve',
  negative: '-ve',
  success: 'success',
  NEGATIVE: 'NEGATIVE',
  emptyString: '',
  YES: 'YES',
  NA: 'NA',
  NO: 'NO',
  No: 'No',
  NONE: 'NONE',
  mouseup: 'mouseup',
  chat: 'chat',
  cards: 'cards',
  user: 'user',
  Error: 'Error',
  Deleted: 'Deleted !!',
  DomainAdded: 'Domain Added!!',
  DataIsEmpty: '"Data is Empty!"',
  Loading: 'Loading...',
  Add: 'Add',
  Delete: 'Delete',
  Cancel: 'Cancel',
  Description: 'Description',
  Enter: 'Enter',
  Login: "Login",
  Email: "Email",
  email: "email",
  text: "text",
  error: "error",
  Submit: "Submit",
  password: "password",
  Response: 'Response:',
  Request: "Request:",
  retry_count: "retry count :",
  accept: 'accept',
  reject: "reject",
}

const gptConstants = {
  GileadGPT: 'Gilead GPT',
  OncologyClaims: 'Oncology claims',
  Response: 'Response:',
  Query: 'Query:',
  STEPS: 'STEPS',
  VIEW_QUERY: 'VIEW QUERY',
  positions: ["top", "bottom", "left", "right"],
  LetUsKnowWhatWeCanDoBetter: 'Let us know what we can do better',
  PleaseEnterYouFeedback: 'Please enter you feedback!',
  portrait: 'portrait',
  px: 'px',
  a4: 'a4',
  PNG: 'PNG',
  mycontainer: 'mycontainer',
  fullscreen: 'fullscreen',
  fullExpand: 'fullExpand',
  fullscreenToggle: 'fullscreenToggle',
  table: 'table',
  graph: 'graph',
  isFavQn: 'isFavQn',
  sqlQuery: 'sql query',
  Clear: 'Clear',
  SampleQuestions: 'Sample Questions',
  SQLQuery: 'SQL Query',
  QueryResults: 'Query Results',
  NoQueryResults: 'No Query Results',
  Question: "Question"
}

const domainConstants = {
  domain: 'domain',
  AddDomainKnowledge: 'Add Domain Knowledge',
  EnterNewDomainknowledge: 'Enter new domain knowledge',
  tabs: [
    { id: "domain", label: "Domain Knowledge" },
    { id: "instructions", label: "Instructions" },
    { id: "pending", label: "Pending Instructions" },
  ],
  instructions: 'instructions',
  pending: 'pending'
}

const labels = {
  dataAnalysis: 'Data Analysis',
  history: 'History',
  favourites: 'Favourites',
  domainContext: 'Domain Context',
  profile: 'Profile',
  logOut: 'Log Out',
  userQuestion: 'User Question:',
  userInstructions: 'User Instructions:',
  NoFavourites: 'No Favourites!',
  NoHistoryData: 'No History data!',
  Password: "Password"
}

const buttonLabels = {
  Accept: "Accept",
  Reject: "Reject",
  Delete: 'Delete',
}

const users = {
  user: 'user',
  admin: "admin",
  hr: 'hr',
  employee: 'employee'
}

const links = {
  dataAnalysis: '/dataAnalysis',
  history: '/history',
  favourites: '/favourites',
  domainContext: '/domainContext',
  pageNotFound: '/pageNotFound'
}

export { domainConstants, CSS, common, labels, buttonLabels, SessionStorageKeys, gptConstants, month, adminDashboard, cardsDetails, sampleQns, users, links };