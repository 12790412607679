import Pako from "pako";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from 'react-responsive';

export const fortmatData = (data) => JSON.stringify(data, null, 2);

export const getCurrentDateTime = () => new Date();

export const hasNoText = (str) => !(str && str.trim() !== "");

export const hasText = (str) => !!(str && str.trim() !== "");

export const isEmptyArray = (val) => val && !val.length;

export const isEmptyObject = (val) => isNullOrEmpty(val) || (val && Object.keys(val).length === 0);

export const isNullOrEmpty = (str) => !str;

export const isPastDateTime = (datetime) => datetime < getCurrentDateTime();

export const parseArray = (arr, replaceStr = []) => isNullOrEmpty(arr) || isEmptyArray(arr) ? replaceStr : arr;

export const parseStr = (str, replaceStr = "") => isNullOrEmpty(str) ? replaceStr : str;

export const strToLowercase = (str) => str.toLowerCase();

export const strToUppercase = (str) => str.toUpperCase();

export const sortArrayOfObjects = (arr, keyToSort, direction) => {
  if (direction === "none") return arr;

  const compare = (objectA, objectB) => {
    const valueA = objectA[keyToSort]
    const valueB = objectB[keyToSort]

    if (valueA === valueB) return 0;

    if (valueA > valueB) {
      return direction === "ascending" ? 1 : -1
    } else {
      return direction === "ascending" ? -1 : 1
    }
  }

  return arr.slice().sort(compare)
}

export const disableBackNavigation = () => {
  // Add a new state to the history
  const stateObj = { page: "your-page" };
  window.history.pushState(stateObj, "", window.location.href);

  // Listen for the popstate event to prevent manual navigation
  window.addEventListener("popstate", () => {
    window.history.pushState(stateObj, "", window.location.href);
  });
};

export const currentMonth = () => {
  const currentDate = new Date();
  // console.log(currentDate.toLocaleString('default', { month: 'long' }))
  return currentDate.toLocaleString("default", { month: "long" });
}

export const currentYear = () => new Date().getFullYear();

export const getRandomArrayElements = (array, numElements) => {
  const shuffledArray = array.slice();

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const randomUUID = uuidv4();
    const index = parseInt(randomUUID.replace(/[^0-9]/g, ""), 10) % (i + 1);

    [shuffledArray[i], shuffledArray[index]] = [shuffledArray[index], shuffledArray[i]];
  }
  return shuffledArray.slice(0, numElements);
}

export const fileToBuffer = (file) => {
  const reader = new FileReader();

  reader.onload = function (event) {
    const buffer = event.target.result;
    return (null, buffer);
  };

  reader.onerror = function (event) {
    return (event.target.error, null);
  };

  reader.readAsArrayBuffer(file);
}

export const fileUpload = async (url, file) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        // 'Content-Type': selectedFile.type,
        "x-ms-blob-type": "BlockBlob",
        "x-ms-version": "2017-11-09"
      },
    });

    if (response.ok) {
      console.log("File uploaded successfully.", response);
      return response;
    } else {
      console.error("Failed to upload file. Status:", response);
    }
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export const decode = () => {
  let data = 'eJxNjsmSmzAABf9FZ0OxJ/g0E3uMRRkxXkAjbkjIlhCLY2AwSuXfU77l/Lq73h8w9op3YA34EgsaMZnKGGYa2kjCAXYnn21gANX9K9/EocmX2GZOvpzxUaYSOkWUqwRDXUhLI523h8tJkjobSZ0tZLHsNMqspE2exVbNqFbysIkbind3+op2yC4iIViHeoK9iXRkYm2uin3zXji2KPE8ESccoZxl0TYLc08pwXYD615mbq7KaLcwRwja7s4FPil+hgNsn+J/7ih/4Zdf7Zu5OMMgkbMs8c6Cdf9E+sNLLjcvuRD9+sX37zKtjx7SiY+2tyeqUWhuxfEzwnvvOqrms6ZuqG95Ehgfrmewr9AlBv59+NbbFm4UWIFp4A9YgTUIKl7Z1L0a7g//aniU+gb1HWb4P7nnMLsKrSAEKzAudw7WzgrwtpQNWINhqkT5GETZmfTbfPCqWt5ur81kfQv+/gOwVI8Z'
  const decodedData = atob(data);
  const uint8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }
  return JSON.parse(Pako.inflate(uint8Array, { to: "string" }));
}

export const useDesktopMediaQuery = () => useMediaQuery({ query: '(min-width: 1440px)' });
export const useBigScreenMediaQuery = () => useMediaQuery({ query: '(min-width: 2000px)' });

export const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: '(max-width: 1279px)' });