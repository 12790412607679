import * as apiClient2 from "./httpCommon";
import { apiRoutes } from "./apiRoutes";


export const getAnswers = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.getAnswers, data))?.data;
export const updateQuestion = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.updateQuestion, data))?.data;
export const DislikeFeedback = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.DislikeFeedback, data))?.data;
export const AddToFavorites = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.AddToFavorites, data))?.data;
export const getFavorites = async (data) =>
  (await apiClient2.getAPICall(apiRoutes.getFavorites, data))?.data;
export const displayDomainKnowledge = async (data) =>
  (await apiClient2.getAPICall(apiRoutes.displayDomainKnowledge, data))?.data;
export const deleteDomainKnowledge = async (data) =>
  (await apiClient2.deleteAPICall(apiRoutes.deleteDomainKnowledge, data))?.data;
export const addDomainKnowledge = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.addDomainKnowledge, data))?.data;
export const getPendingInstructions = async (data) =>
  (await apiClient2.getAPICall(apiRoutes.getPendingInstructions, data))?.data;
export const rejectPendingInstruction = async (data) =>
  (await apiClient2.deleteAPICall(apiRoutes.rejectPendingInstruction, data))?.data;
export const acceptPendingInstruction = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.acceptPendingInstruction, data))?.data;
export const deleteFavorites = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.deleteFavorites, data))?.data;
export const displayAdminInstructions = async (data) =>
  (await apiClient2.getAPICall(apiRoutes.displayAdminInstructions, data))?.data;
export const deleteAdminInstruction = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.deleteAdminInstruction, data))?.data;
export const feedbackApi = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.feedback, data))?.data;
export const getFavAns = async (data) =>
  (await apiClient2.postAPICall(apiRoutes.getFavAns, data))?.data;
