export const loginData = [
  {
    userId: "8dqq5342-4602-4cbc-8a70-f68a41c8af80",
    username: "sakshamkhatri",
    email: "saksham.khatri1@gilead.com",
    password: "saksham@123",
    userType: "admin",
  },
  {
    userId: "8dgg5342-4602-4cbc-8a70-f68a41c8af80",
    username: "debojyotighosal",
    email: "debojyoti.ghosal3@gilead.com",
    password: "debojyoti@123",
    userType: "admin",
  },
  {
    userId: "8dmm5342-4602-4cbc-8a70-f68a41c8af80",
    username: "jatinmakkar",
    email: "jatin.makkar@gilead.com",
    password: "jatin@123",
    userType: "admin",
  },
  {
    userId: "8dzz5342-4602-4cbc-8a70-f68a41c8af80",
    username: "SampleUser1",
    email: "sample.user1@gilead.com",
    password: "user@123",
    userType: "user",
  },
  {
    userId: "8dff5342-4602-4cbc-8a70-f68a41c8af80",
    username: "SampleUser2",
    email: "sample.user2@gilead.com",
    password: "user@123",
    userType: "user",
  },
  {
    userId: "8dzz5342-4602-4cbc-8a70-f68a41c8af90",
    username: "SampleUser3",
    email: "sample.user3@gilead.com",
    password: "admin@123",
    userType: "admin",
  },
];