import React, { useEffect, useState } from 'react';
import { GridTable } from './GridTable';
import { isEmptyArray } from '../util';
import { gptConstants } from '../helper';

const QueryViewer = ({ queryData, queryBtnData, tableHeight, row, column }) => {
  const [selectedBtn, setSelectedBtn] = useState(1);
  const [body, setBody] = useState({});
  const [tableHeader, setTableHeader] = useState([]);

  useEffect(() => {
    if (queryData.length > 0 && queryData[selectedBtn - 1]) {
      const selectedData = queryData[selectedBtn - 1];
      setBody(selectedData);
      setTableHeader(Object.keys(selectedData.query_result[0] || {}));
    }
  }, [selectedBtn, queryData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {!isEmptyArray(tableHeader) ?
        <>
          <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', gap: '10px', flexWrap: 'wrap' }}>
            {queryBtnData.map((ele, i) => (
              <button
                key={i}
                className={selectedBtn === ele?.id ? 'activeLink' : 'iconLabel'}
                onClick={() => setSelectedBtn(ele.id)}
                style={{ border: "none", backgroundColor: "white" }}
              >
                <b>{ele.label}</b>
                <hr className='m-0'/>
              </button>
            ))}
          </div>
          <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <b className='markdown-content' style={{ margin: '0px' }}>{gptConstants.Question}</b><br />
            <span className='query-text' style={{ backgroundColor: '#B0E0E6', color: '#0000FF' }}>{body.user_query}</span>
          </span>
          <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <b className='markdown-content' style={{ margin: '0px' }}>{gptConstants.SQLQuery}</b><br />
            <span className='query-text' style={{ backgroundColor: '#B0E0E6', color: '#0000FF' }}>{body.query}</span>
          </span>

          {!isEmptyArray(tableHeader) && body.query_result && !isEmptyArray(body.query_result) && (
            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <b className='markdown-content' style={{ margin: '0px' }}>{gptConstants.QueryResults}</b>
              <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <GridTable
                  width={"90%"}
                  height={'60vh'}
                  row={body.query_result?.slice(1)}
                  column={tableHeader.map((header) => ({
                    headerName: header,
                    field: header,
                  }))}
                />
              </div>
            </span>
          )}
        </> :
        <b className='steps-list' style={{ margin: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{gptConstants.NoQueryResults}</b>
      }
    </div>
  );
};

export { QueryViewer };
