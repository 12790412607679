import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { deleteAdminInstruction, displayAdminInstructions } from "../api/commonApi";
import { isEmptyArray, isEmptyObject, isNullOrEmpty, SkeletonLoader } from "../util";
import { isLoading } from "../redux/features/user/userSlice";
import { adminDashboard, buttonLabels, common, ERROR_MESSAGES } from "../helper";

const Instructions = () => {
  const [data, setData] = useState([]);
  const loading = useSelector((state) => state?.user?.loading);
  const dispatch = useDispatch();

  const getInstructions = async () => {
    dispatch(isLoading(true));
    const response = await displayAdminInstructions();
    const dataArray = [];
    if (!isEmptyObject(response?.data) && !isNullOrEmpty(response?.data)) {
      for (const [key, value] of Object.entries(response.data)) {
        dataArray.push({ key: key, value: value });
      }
      !isEmptyArray(dataArray) && setData(dataArray);
    } else {
      setData([])
    }
    dispatch(isLoading(false));
  };

  const formatText = (input) => {
    return input.split("\n").map((item, key) => {
      return (<span key={key}> {item} <br /></span>);
    });
  };

  const deleteInstruction = async (ele) => {
    const payload = { question: ele.key };
    const response = await deleteAdminInstruction(payload);
    if (response?.status === common.success) {
      toast.success(common.Deleted);
    } else {
      toast.error(ERROR_MESSAGES.FailedToDeleted);
    }
    response?.status === common.success && getInstructions();
    response?.status === common.success && data.length === 1 && setData([]);
  };

  useEffect(() => {
    getInstructions();
  }, []);

  return (
    <div className="domain-knowledge-container pr-4">
      <div className="domain-knowledge-container">
        {!isEmptyArray(data) && !loading ? (
          data?.map((ele, index) => (
            <div key={index} className="row knowledge-card m-0">
              <p className="text-start domain-list m-0">
                <span style={{ fontWeight: 600 }}>
                  {adminDashboard.UserQuestion}{" "}
                </span>
                {ele.key}
              </p>
              <p className="col-10 text-start domain-list m-0">
                <span style={{ fontWeight: 600 }}>
                  {adminDashboard.Instructions}{" "}
                </span>
                {formatText(ele.value)}
              </p>
              <button
                className=" col-2 delete-button"
                onClick={() => deleteInstruction(ele)}
              >
                {buttonLabels.Delete}
              </button>
            </div>
          ))
        ) : loading ? (
          <SkeletonLoader type={common.cards} />
        ) : (
          <div className="markdown-content bold w-100 h-100 mt-5 d-flex justify-content-center align-items-center">
            {common.DataIsEmpty}
          </div>
        )}
      </div>
    </div>
  );
};

export { Instructions };
