
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginData } from "../util";
import { addUser } from "../redux/features/user/userSlice";
import { common, labels, ERROR_MESSAGES, MESSAGES,  links, SessionStorageKeys } from "../helper";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_MESSAGES.must_be_a_valid_email_address)
      .max(30, ERROR_MESSAGES.email_must_be_less_than_20_characters)
      .required(ERROR_MESSAGES.email_is_required),
    password: Yup.string()
      .min(8, ERROR_MESSAGES.password_must_be_at_least_8_characters)
      .required(ERROR_MESSAGES.password_is_required),
  });

  return (
    <div className="loginContainer">
      <Formik
        initialValues={{
          email: common.emptyString,
          password: common.emptyString,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const user = loginData.find(
            (user) =>
              user.email === values.email && user.password === values.password
          );
          if (user) {
            const userLoggedIn = {
              userId: user.userId,
              username: user.username,
              email: user.email,
              userType: user.userType,
              token: "jkbvnsfjkvbsrjkb7236y5r79vjkbnsdf2478tyrfhb"
            };
            dispatch(addUser(userLoggedIn));
            resetForm();
            setSubmitting(false);
            navigate(links.dataAnalysis);
            localStorage.setItem(SessionStorageKeys.user, JSON.stringify(userLoggedIn))
            localStorage.setItem(SessionStorageKeys.token, "jkbvnsfjkvbsrjkb7236y5r79vjkbnsdf2478tyrfhb")
            localStorage.setItem(SessionStorageKeys.userId, user.userId)
            localStorage.setItem(SessionStorageKeys.email, user.email)
            localStorage.setItem(SessionStorageKeys.username, user.username)
            toast.success(MESSAGES.Login_Successfull);
          } else {
            toast.error(ERROR_MESSAGES.Invalid_Credentials);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="loginForm"
            autoComplete="off"
          >
            <div>
              <span className="loginTitle bold font20 blackColor textCenter">
               {common.Login}
              </span>
            </div>
            <br />
            <Form.Group className="formGrupPadding" controlId="formEmail">
              <span className="loginTitleText semi-bold h-100 font14 blackColor textCenter">
                {common.Email}
              </span>
              <Form.Control
                type={common.text}
                name={common.email}
                placeholder={common.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="new-password" // Prevents autofill
                className={touched.email && errors.email ? common.error : null}
              />
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="formGrupPadding" controlId="formPassword">
              <span className="loginTitleText semi-bold h-100 font14 blackColor textCenter">
                {labels.Password}
              </span>
              <Form.Control
                type={common.password}
                name={common.password}
                placeholder={labels.Password}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="new-password" // Prevents autofill
                className={touched.password && errors.password ? errors.email : null}
              />
              {touched.password && errors.password ? (
                <div className="error-message">{errors.password}</div>
              ) : null}
            </Form.Group>
            <div className="buttonStyle">
              <button
                className="submitButton semi-bold font14 whiteColor textCenter"
                type="submit"
                disabled={isSubmitting}
              >
                {common.Submit}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { Login };
