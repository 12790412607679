import { createSlice } from "@reduxjs/toolkit";

const favAnsSlice = createSlice({
  name: "favAnswer",
  initialState: {
    value: [],
    favState: '',
  },
  reducers: {
    updateFavAns(state, action) {
      // let oldResponse = [...state.value];
      let currentResponse = [action.payload];
      // let newResponse = [...oldResponse, currentResponse];

      state.value = currentResponse;
    },
    clearFavAns(state) {
      state.value = [];
    },
    setFavState(state, action) {
      state.favState = action.payload;
    },
  },
});

export const { updateFavAns, clearFavAns, setFavState } = favAnsSlice.actions;
export default favAnsSlice.reducer;
