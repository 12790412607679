import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const GridTable = ({ height, width, row, column }) => {
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: false,
      flex: 1,
      minWidth: 100, // Optional: sets a minimum width for the columns
      resizable: true,
    };
  }, []);

  return (
    <div className="ag-theme-quartz" style={{ height: height, width: width, marginBottom: '30px' }}>
      <AgGridReact
        rowData={row}
        columnDefs={column}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export { GridTable };