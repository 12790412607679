import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { cardsDetails, common, gptConstants, sampleQns, SessionStorageKeys } from "../helper";
import { isEmptyObject, handleFeedbackApi } from "../util";
import { isLoading } from "../redux/features/user/userSlice";
import { getAnswers } from "../api/commonApi";
import { addToHistory, resetValue, updateHistoryAns } from "../redux/features/history/historySlice";

import "../styles/DefaultAnalysis.css";
import GptLogo from "../assets/svg/GILEAD_GPT.svg";

const DefaultAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQnClick = async (qn) => {
    navigate("/dataAnalysis");
    dispatch(isLoading(true));
    const q_id = uuidv4();
    let payload = {
      question_id: q_id,
      question: [{ role: common.user, content: qn.trim() }],
    };

    try {
      const responseData = await getAnswers(payload);
      if (!isEmptyObject(responseData)) {
        const historyData = [...responseData?.answers?.past];
        sessionStorage.setItem(SessionStorageKeys.prevHistory, JSON.stringify(historyData));
        const user = JSON.parse(localStorage.getItem(common.user));
        const updateResponseData = {
          question_id: responseData?.question_id,
          userId: user.userId,
          question: qn.trim(),
          answers: responseData?.answers,
          isFavQn: false,
          feedbackType: common.NA,
        };
        await handleFeedbackApi(updateResponseData);
        dispatch(
          updateHistoryAns({
            payload: updateResponseData,
            shouldReplace: false,
          })
        );
        dispatch(addToHistory({ payload: updateResponseData, shouldReplace: false }));
      }
    } catch (error) {
      console.error(common.Error, error)
    } finally {
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    dispatch(resetValue());
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex align-items-center gap-3">
        <img src={GptLogo} alt={"GptLogo"} className={"gptLogo"} />
        <div className="d-flex flex-column">
          <strong className={"logo_title"}>{gptConstants.GileadGPT}</strong>
          <span className={"logo_tag"}>{gptConstants.OncologyClaims}</span>
        </div>
      </div>
      <div className={`d-flex flex-wrap justify-content-center mt-3 pr-3 pl-0 cardContainer`} >
        {cardsDetails?.length > 0 &&
          cardsDetails?.map((ele, i) => (
            <div className={`d-flex justify-content-center align-items-center cards`} key={i} >
              <img src={ele.img} alt={ele.img} className={"cardImgs"} />
              <div className={"cardTxt"}>
                <strong className={"header"}>{ele.header}</strong>
                <span className={"subHeader"}>{ele.subHeader}</span>
              </div>
            </div>
          ))}
      </div>
      <span className={`qnTitle align-self-start`}>{gptConstants.SampleQuestions}</span>
      <div className={`d-flex flex-wrap mr-3 qnContainer`} >
        {sampleQns?.map((qshn, i) => (
          <button key={i} className={"qnsCard"} onClick={() => handleQnClick(qshn)}>
            {qshn}
          </button>
        ))}
      </div>
    </div>
  );
};

export { DefaultAnalysis };
